import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import './App.css';

import 'css/Driver.css'

// for IE11 support
import "isomorphic-fetch"
import { polyfill } from 'es6-promise';
polyfill();

const Layout = React.lazy(() => import('./containers/Layout'));

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem('accessToken')
      ? <Component {...props} />
      : <Redirect to='/login' />
    )} />
  )

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Signup = React.lazy(() => import('./views/Pages/Signup'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/signup" name="Signup Page" render={props => <Signup {...props}/>} />

            <PrivateRoute path="/" name="Home" component={Layout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
